(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbAccount.controller:AccountCtrl
   *
   * @description
   *
   */
  angular
    .module('wbAccount')
    .controller('AccountCtrl', AccountCtrl);

  function AccountCtrl(user, FormControl, $q, LoggedUser) {
    var vm = this
      , formControl = new FormControl();

    formControl.setSuccessText('account.updated');

    vm.user = user;

    vm.isProcessing = function isProcessing() {
      return formControl.isProcessing();
    };

    vm.getServerValidation = function getServerValidation() {
      return formControl.getServerValidation();
    };

    vm.submit = function submit() {
      var deferred = $q.defer();

      formControl.process(deferred.promise)
        .then(function() {
          LoggedUser.setUserById(vm.user.id);
        });

      vm.user.$update(deferred.resolve, deferred.reject);
    };
  }
}());
